<template lang="pug">
    .p-2
        .text-sm.mb-10.font-bold {{ item.questionText }}
        template(v-for="(variant, index) in item.questionVariants")
            .flex.items-center.mb-10                
                .min-w-25.min-h-25.mr-10.rounded-full.border-1.bg-white.cursor-pointer(
                    :ref="`q-${index}`" 
                    @click="() =>{ if (!result) {onVariant(variant, index)} }"
                )
                
                .text-sm.block {{ variant.variantText }}
        
        template(v-if="result && !result.isCorrect")
            .text-sm.text-gray-400 Правильный ответ: {{ item.questionVariants.find(v => {  return v.isCorrect === true}).variantText }}
</template>

<script>
export default {
    name: "QuizBlock",
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        savedResult: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            result: null,
        }
    },
    methods: {
        onVariant(variant, index) {
            this.result = variant
            this.result.questionText = this.item.questionText
            if (variant.isCorrect) this.result.isSelected = true
            
            const el = this.$refs[`q-${index}`][0]
            
            if (variant.isCorrect) {
                el.classList.add('bg-green-500')
            } else {
                el.classList.add('bg-red-500')
            }

            this.$emit('value', this.result)
        }
    },
    watch: {
        savedResult: {
            handler(val) {
                if (val) {
                    this.result = this.savedResult
                    const foundIndex = this.item.questionVariants.findIndex(v => v.variantText === this.savedResult.variantText)
                    if (this.savedResult.isCorrect) {
                        this.$refs[`q-${foundIndex}`][0].classList.add('bg-green-500')
                    } else {
                        this.$refs[`q-${foundIndex}`][0].classList.add('bg-red-500')
                    }
                }

            },
            deep: true,
            immediate: true,
        }
    }
}
</script>