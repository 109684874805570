<template lang="pug">
    unauthorized-layout
        template
            quiz-page
</template>

<script>
import UnauthorizedLayout from '@/layouts/unauthorized-layout';
import Quiz from '@/components/views/quiz';

export default {
    name: "ApplyPage",
    metaInfo: {
        title: 'Бизнес-реалити Сверхновая: Правила',
    },
    components: {
        'unauthorized-layout': UnauthorizedLayout,
        'quiz-page': Quiz,
    },
    created() {
        const script_el = document.createElement('script');
        const jscode = document.createTextNode("dataLayer.push({ 'event': 'scrn', 'screenName': '/details', 'buttonLocation': null, 'actionGroup': 'non_interactions', 'touchPoint': 'web'});")
        script_el.appendChild(jscode);
        document.head.appendChild(script_el)
    }    
}
</script>