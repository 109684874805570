const QUIZ_TITLE = ''

const QUESTIONS = [
    {
        questionText: 'Перед вами стоит задача сформулировать описание вашего продукта. Что из перечисленного ниже вы должны учесть, чтобы не сделать ошибку?',
        questionVariants: [
            { variantText: 'Себестоимость продукта', isCorrect: false, isSelected: false },
            { variantText: 'Портрет клиента', isCorrect: true, isSelected: false },
            { variantText: 'Каналы продвижения', isCorrect: false, isSelected: false },
        ] 
    },
    {
        questionText: 'Правильно сформулированная ценность продукта – это основа для его успешного продвижения продукта на рынке. Выберите правильный вариант формулировки ценности продукта.',
        questionVariants: [
            { variantText: 'Докторская колбаса «Фермерская» соответствует гостам', isCorrect: false, isSelected: false },
            { variantText: 'Косметика «Beauty» с микроиглами избавляет чувствительную кожу от отеков на 15%', isCorrect: true, isSelected: false },
            { variantText: 'Удобные бьюти-кейсы для визажистов', isCorrect: false, isSelected: false },
            { variantText: 'Двигатели мощностью 2кВт в удобной упаковке', isCorrect: false, isSelected: false },
        ] 
    },
    {
        questionText: 'Перед запуском любого продукта вам необходимо определить целевую аудиторию. Какое из описаний ниже корректно?',
        questionVariants: [
            { variantText: 'Владельцы домашних животных', isCorrect: false, isSelected: false },
            { variantText: 'Семьи, проживающие в Ленинградской области, с маленькими детьми', isCorrect: false, isSelected: false },
            { variantText: 'Дошкольники, которым нужен цветной пластилин для уроков трудов', isCorrect: false, isSelected: false },
            { variantText: 'Владельцы бизнеса в Иркутске с оборотом более 10-30 млн рублей', isCorrect: true, isSelected: false },
        ] 
    },
    {
        questionText: 'Через пару минут у вас начнется интервью с представителем вашей целевой аудитории. Вы открываете заранее подготовленные вопросы и понимаете, что три из них совсем не подходят, а вот один очень даже толковый. Выберите вопрос, который действительно стоит задавать пользователям.',
        questionVariants: [
            { variantText: 'Будете ли вы использовать мой продукт?', isCorrect: false, isSelected: false },
            { variantText: 'Заплатили бы вы за мой продукт?', isCorrect: false, isSelected: false },
            { variantText: 'Как вы решаете эту проблему?', isCorrect: true, isSelected: false },
        ] 
    },
    {
        questionText: 'Выявление проблемы у клиента является основой для формирования ценности продукта. Когда вы будете уверены, что у вашего клиента действительно есть проблема?',
        questionVariants: [
            { variantText: 'Клиент рассказывает, как мог бы решить проблему', isCorrect: false, isSelected: false },
            { variantText: 'Клиент рассказывает, как уже решал проблему', isCorrect: true, isSelected: false },
            { variantText: 'Клиент знает, как решить проблему, но не делает этого', isCorrect: false, isSelected: false },
        ] 
    },
    {
        questionText: 'Конкуренты – один из важнейших факторов, влияющих на успех вашего проекта. Как вы помните, конкуренты могут быть прямыми и косвенными. Представим, что вы открываете булочную, кто ваш косвенный конкурент?',
        questionVariants: [
            { variantText: 'Сеть розничных магазинов', isCorrect: false, isSelected: false },
            { variantText: 'Кофейня', isCorrect: false, isSelected: false },
            { variantText: 'Пекарни', isCorrect: false, isSelected: false },
            { variantText: 'Доставка наборов для выпечки', isCorrect: true, isSelected: false },
        ] 
    },
    {
        questionText: 'Чтобы продвигать продукт на рынке вы приступили к работе с рекламой. Проделали большую работу, но она не оправдала ваших ожиданий. Почему может не работать реклама?',
        questionVariants: [
            { variantText: 'Ваша ЦА – это дедушки, они не смотрят рекламу в соц.сетях', isCorrect: true, isSelected: false },
            { variantText: 'В креативах вы не использовали фирменный стиль', isCorrect: false, isSelected: false },
            { variantText: 'Вы не отвечаете на обратную связи клиентов', isCorrect: false, isSelected: false },
        ] 
    },
    {
        questionText: 'Перед тем, как клиент заплатит за продукт, он должен понять, какую выгоду он получит. Из чего вытекает выгода продукта?',
        questionVariants: [
            { variantText: 'Ситуация', isCorrect: false, isSelected: false },
            { variantText: 'Решение', isCorrect: true, isSelected: false },
            { variantText: 'Проблема', isCorrect: false, isSelected: false },
            { variantText: 'Портрет клиента', isCorrect: false, isSelected: false },
        ] 
    },
    {
        questionText: 'Если ваше ценностное предложение ничем не отличается от предложений конкурентов, тогда вы будете вынуждены конкурировать по …',
        questionVariants: [
            { variantText: 'Цене', isCorrect: true, isSelected: false },
            { variantText: 'Количеству продаж', isCorrect: false, isSelected: false },
            { variantText: 'По продвижению в социальных сетях', isCorrect: false, isSelected: false },
        ] 
    },
    {
        questionText: 'Модель Остервальда является одним из популярных инструментов планирования бизнеса. В бизнес-модели есть 9 разделов, что важно продумать еще 10-ым пукнтом?',
        questionVariants: [
            { variantText: 'Риски проекта', isCorrect: true, isSelected: false },
            { variantText: 'Бюджет на рекламу', isCorrect: false, isSelected: false },
            { variantText: 'Развитие ассортимента', isCorrect: false, isSelected: false },
        ] 
    },
]

export {
    QUIZ_TITLE,
    QUESTIONS,
}