<template lang="pug">
    div
        label.text-label.font-cormorantBold {{ label }}
        input(
            :type="inputType" 
            class="w-full mt-1 p-3 text-black caret-slate-300 border-1 rounded border-white outline-none focus:border-gray-300"
            :class="{'bg-red-100': error}"
            @input="onValue"
            :value="value"
            :placeholder="placeholder"
            v-mask="inputMask"
        )
</template>

<script>
export default {
    name: "TextInput",
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        inputType: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
        },
        error: {
            type: Boolean,
            default: false,
        },
        mask: {
            type: String,
            default: '',
        }
    },
    computed: {
        inputMask() {
            return this.mask
        }
    },
    methods: {
        onValue({ target }) {
            this.$emit('value', target.value)
        }
    }
}
</script>