<template lang="pug">
    Button(
        class="cursor-default min-w-fit whitespace-nowrap rounded-full button-active" 
        :class="{'bg-accent text-white cursor-pointer': isActive, 'text-gray-300': !isActive}" 
        :value="value" @click="isActive ? $emit('click') : false"
    )
</template>

<script>
import Button from '@/components/common/buttons/button';

export default {
    name: "DefaultButton",
    props: {
        value: {
            type: String,
        },
        isActive: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        Button
    }
}
</script>

<style lang="sass" scoped>
.button-active
    transition: all .05s ease
    &:active
        background-color: darken(#FA1D27, 8) !important
</style>