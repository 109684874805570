//Forms
const FORMS_LABELS = {
    name: 'имя',
    description: 'описание',
    email: 'e-mail',
    password: 'пароль',
    user_name: 'имя',
    user_lastname: 'фамилия',
    user_patronymic: 'отчество',
    user_photo: 'фото',
}

//Buttons
const BUTTONS_VALUES = {
    upload: 'загрузить',
    sign_in: 'войти',
    log_out: 'выйти',
    edit: 'редактировать',
    add: 'добавить',
    delete: 'удалить',
    save: 'сохранить',
    cancel: 'отмена',
    select: 'выбрать',
    apply: 'Подать заявку',
    details: 'Узнать больше о правилах шоу',
}

//Switchers
const SWITCHERS_VALUES = {
    can_delete: 'Удаление разрешено',
    cannot_delete: 'Удаление запрещено',
    blocked: 'Заблокирован',
    active: 'Активен',

}

export {
    FORMS_LABELS,
    BUTTONS_VALUES,
    SWITCHERS_VALUES,
}