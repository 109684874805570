<template lang="pug">
    .relatives.flex.flex-col.items-center(class="pl-20 pr-20")
        .w-full(class="1280:w-1160")
            .flex.items-center.pr-20.pl-20
                img.block.mr-5(:src="IMGS.arrow_back")
                span(@click="onBackTop" class="block text-sm text-accent cursor-pointer hover:underline") Назад
        screen-wrapper
            template(v-slot:title)
                .font-cormorantBold(class="mb-20 text-5xl 1024:text-6xl") Пройдите тест
            template(v-slot:content)
                .flex.flex-col(class="w-full 1024:w-600")
                    section.mb-40
                        .text-sm.mb-40 {{ QUIZ_TITLE }}
                        template(v-for="item in QUESTIONS")
                            quiz-block.mb-20(
                                :item="item"
                                :savedResult="ifResultMatch(item)"
                                @value="onSelectQuestion"
                            )
                    
                        template(v-if="result.length === QUESTIONS.length")
                            p Ваш результат: {{ onResultCount() }} из {{ QUESTIONS.length }}

                    template(v-if="(result.length === QUESTIONS.length) && !onLocalStorageGetSentItem()")
                        .text-3xl.mb-20.font-cormorantBold Заполните форму для отправки результата
                        .flex.flex-col.justify-center.gap-20.mb-40
                            text-input(
                                :label="FORM.firstname" 
                                class="w-full"
                                @value="form.firstname = $event" 
                                :value="form.firstname"
                                :error="validation.firstname"
                            )
                            text-input(
                                :label="FORM.lastname" 
                                class="w-full"
                                @value="form.lastname = $event" 
                                :value="form.lastname"
                                :error="validation.lastname"
                            )
                            text-input(
                                :label="FORM.patronymic" 
                                class="w-full"
                                @value="form.patronymic = $event" 
                                :value="form.patronymic"
                                :error="validation.patronymic"
                            )

                        template(v-if="!isSending")
                            .flex.items-center.flex-col(class="768:flex-row")
                                regular-button.mr-0.mb-20(
                                    class="h-60 w-300 border-none 768:mr-40 768:mb-0"
                                    :value="!isSending ? 'отправить' : 'идет отправка...'" 
                                    @click="!isSending ? onApply() : false"
                                )

                        template(v-if="errorText")
                            .inline-flex.bg-red-100.p-20.mb-20.mt-20.w-full(class="768:w-500")
                                .text-sm {{ errorText }}
                    
                    template(v-if="successText")
                        .inline-flex.bg-green-100.p-20.mb-20.mt-20.w-full(class="768:w-500")
                            .text-sm {{ successText }}
                        

</template> 

<script>
//Components
import BaseScreenWrapper from '@/components/views/home/components/BaseScreenWrapper';
import TextInput from '@/components/common/forms/text-input';
import RegularButton from '@/components/common/buttons/regular-button';
import QuizBlock from './components/quiz-block.vue';
//Config
import { FORM } from '@/configs/text';
import { BUTTONS_VALUES } from '@/configs/names';
import { isMobile } from '@/helpers/is-mobile'
import { QUIZ_TITLE, QUESTIONS } from './config';
//Store
import { QuizNameSpace, QuizActionTypes } from '@/store/quiz/types';

export default {
    name: "ApplyComponent",
    components: {
        'screen-wrapper': BaseScreenWrapper,
        'text-input': TextInput,
        'regular-button': RegularButton,
        'quiz-block': QuizBlock,
    },
    data() {
        return {
            result: [],
            form: {
                firstname: '',
                lastname: '',
                patronymic: '',
            },
            validation: {
                firstname: false,
                lastname: false,
                patronymic: false,
            },
            errorText: '',
            successText: '',
            isSending: false,
        }
    },
    computed: {
        IMGS() {
            return {
                title: require('./assets/title.svg'),
                arrow_back: require('./assets/arrow-back.svg'),
            }
        },
        OPTIONS() {
            return [
                { name: 'Например, малый бизнес', value: '', disabled: true, selected: true },
                { name: 'idea', value: 'Бизнес идея' }
            ]
        },
        FORM() {
            return FORM
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        isMobile() {
            return isMobile()
        },
        QUIZ_TITLE() {
            return QUIZ_TITLE
        },
        QUESTIONS() {
            return QUESTIONS
        }
    },
    mounted() {
        if (JSON.parse(localStorage.getItem('sverxnovaya'))) this.onLocalstorageGetItem()
    },  
    methods: {
        onValidation() {
            let errors = 0
            for (const key in this.form) {
                if (key !== 'patronymic'){
                    if (this.form[key] === '') {
                        this.validation[key] = true
                        errors++
                    } else {
                        this.validation[key] = false
                    }
                }
            }
            
            return errors === 0 ? false : true
        },

        onBackTop() {
            this.onBack()
        },

        onBackBottom() {
            this.onBack()
        },

        onBack() {
            if (this.isMobile) {
                this.$router.push('/')
            } else {
                window.open('/', '_self')
            }
        },
        
        isEmpty() {
            return !this.text ? false : true
        },

        async onApply() {
            this.errorText = ''
            if (!this.onValidation()) {
                try {
                    this.isSending = !this.isSending
                    const data = await this.$store.dispatch(`${QuizNameSpace}/${QuizActionTypes.AddQuiz}`, { 
                            ...this.form,
                            answers: this.result,
                            quiz_result: this.onResultCount(),
                        }
                    )
                    if (!data.error_message) {
                        this.successText = 'Результат теста отправлен'
                        this.onLocalStorageSetSentItem()
                    } else {
                        this.errorText = data.error_message
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    this.isSending = !this.isSending
                }
            } else {
                this.errorText = 'Заполните обязательные поля'
            }
        },
        
        onSelectQuestion(value) {
            this.result.push(value)
            this.onLocalstorageSetItem()
            this.onLocalstorageGetItem()
        },
        onLocalstorageSetItem() {
            localStorage.setItem('sverxnovaya', JSON.stringify(this.result))
        },
        onLocalstorageGetItem() {
            const ls = JSON.parse(localStorage.getItem('sverxnovaya'))
            this.result = ls
        },
        onLocalStorageSetSentItem() {
            localStorage.setItem('sverxnovaya-sent', JSON.stringify(1))
        },
        onLocalStorageGetSentItem() {
            const ls = JSON.parse(localStorage.getItem('sverxnovaya-sent'))
            return ls
        },
        ifResultMatch(item) {
            const result = this.result.find(v => v.questionText === item.questionText)
            if (result && result !== undefined) return result
        },
        onResultCount() {
            const correct_total = this.result.filter(v => { return v.isCorrect })
            return correct_total.length
        }
    },
}
</script>
